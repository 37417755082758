import { useCallback, useState } from 'react';

export default function useModal() {
  const [visible, setVisible] = useState<boolean>(false);

  const close = useCallback(() => setVisible(false), [setVisible]);
  const open = useCallback(() => setVisible(true), [setVisible]);

  return { visible, open, close };
}
