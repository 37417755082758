import { callFinish } from 'src/lib/utils';
import CustomModal from 'src/components/common/Modal/CustomModal';

function ErrorPage() {
  const handleOnOk = () => {
    callFinish();
  };

  return (
    <CustomModal onOk={handleOnOk}>
      <>
        현재 접속이 원활하지 않습니다. <br /> 잠시 후 다시 이용해 주세요.
      </>
    </CustomModal>
  );
}

export default ErrorPage;
