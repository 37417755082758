import styled from '@emotion/styled';
import React from 'react';
import { colors } from 'src/styles/theme';

interface ICategory {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  style?: React.CSSProperties;
  active?: boolean;
}

function Category(props: ICategory) {
  const { children, style, active = false } = props;

  const CategoryComp = styled.button`
    padding: 0.5rem 0.9rem;
    border-radius: 1.6rem;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: center;
    background: ${colors.white_01_basic};
    color: ${colors.grey_04_inactive};

    ${active &&
    `background: ${colors.primary_03_basic};
    color: ${colors.black_01_basic};`}
  `;

  const clickHandler = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    const { onClick } = props;
    if (onClick) onClick(e);
  };

  return (
    <CategoryComp type="button" onClick={clickHandler} style={style}>
      {children}
    </CategoryComp>
  );
}

export default Category;
