import { useQuery, useInfiniteQuery } from 'react-query';
import { get } from 'src/lib/request';
import {
  IWeightGraphList,
  IGoalWeight,
  IBodyGraphList,
  IWeightAllList,
} from 'src/components/Weight/lib/types';
import { getFormattedDate } from '../lib/utils';

export const useGetWeightGraph = (baseDt: string, periodDcd: string) => {
  return useQuery<IWeightGraphList>(
    ['weightGraph', baseDt, periodDcd],
    () => get(`/question/weightGraph?baseDt=${baseDt}&periodDcd=${periodDcd}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

const today = getFormattedDate(new Date());
export const useGetGoalWeight = (baseDt: string = today) => {
  return useQuery<IGoalWeight>(
    ['goalWeight', baseDt],
    () => get(`/goalWeight/getGoalWeight?baseDt=${baseDt}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useGetBodyComposition = (
  baseDt: string,
  periodDcd: string = 'P007',
) => {
  return useQuery<IBodyGraphList>(
    ['bodyComposition', baseDt, periodDcd],
    () =>
      get(`/question/bodyComposition?baseDt=${baseDt}&periodDcd=${periodDcd}`),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      keepPreviousData: true,
    },
  );
};

export const useGetWeightAllList = () => {
  return useInfiniteQuery<IWeightAllList>(
    ['weightList'],
    ({ pageParam = 1 }) =>
      get(`/question/weightList?pageNo=${pageParam}&pageSize=10`),
    {
      getNextPageParam: (lastPage) => {
        const {
          outPageData: { totalPageCount, pageSize: page },
        } = lastPage;
        return page < totalPageCount ? page + 1 : undefined;
      },
    },
  );
};
