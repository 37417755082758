import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { colors } from 'src/styles/theme';

interface ITag {
  children: React.ReactNode;
  type?:
    | 'basic1'
    | 'basic2'
    | 'basic3'
    | 'basic4'
    | 'basic5'
    | 'basic6'
    | 'basic7';
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

function Tag(props: ITag) {
  const { children, type = 'basic1', icon, style, className } = props;

  const types = {
    basic1: css`
      color: ${colors.white_01_basic};
      background-color: ${colors.black_01_basic};
    `,
    basic2: css`
      color: ${colors.black_01_basic};
      border: 1px solid ${colors.black_01_basic};
    `,
    basic3: css`
      color: ${colors.grey_05_subtext};
      border: 1px solid ${colors.grey_02_line};
    `,
    basic4: css`
      color: ${colors.feedback_green_03_basic};
      background-color: ${colors.feedback_green_01_highlight};
    `,
    basic5: css`
      color: ${colors.feedback_orange_03_basic};
      background-color: ${colors.feedback_orange_01_highlight};
    `,
    basic6: css`
      color: ${colors.feedback_red_03_basic};
      background-color: ${colors.feedback_red_01_highlight};
    `,
    basic7: css`
      color: ${colors.black_01_basic};
      background-color: ${colors.grey_01_bg};
    `,
  };

  const SpanStyle = styled.span`
    display: inline-block;
    padding: 0 0.7rem;
    border-radius: 1.2rem;
    text-align: center;
    line-height: 2rem;
    font-size: 1rem;
    font-weight: bold;
    ${types[type]}
  `;

  return (
    <SpanStyle style={style} className={className}>
      {children}
      <span>{icon}</span>
    </SpanStyle>
  );
}

export default Tag;
