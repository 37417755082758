export const colors = {
  primary_02_light: '#ffed99',
  primary_01_highlight: '#fffae5',
  primary_03_basic: '#ffd300',
  primary_04_dark: '#b29300',
  feedback_blue_01_highlight: '#ecf4fe',
  feedback_blue_02_light: '#b5d6fe',
  feedback_blue_03_basic: '#4698fc',
  feedback_blue_04_dark: '#306aaf',
  feedback_green_03_basic: '#25c38f',
  feedback_green_01_highlight: '#e9f9f3',
  feedback_orange_03_basic: '#ff9500',
  feedback_green_02_light: '#a8e7d2',
  feedback_orange_01_highlight: '#fff4e5',
  feedback_orange_02_light: '#ffd599',
  feedback_orange_04_dark: '#b26800',
  feedback_red_01_highlight: '#fdeae5',
  feedback_red_02_light: '#faae99',
  feedback_red_03_basic: '#f23500',
  feedback_red_04_dark: '#a82500',
  feedback_green_04_dark: '#198863',
  reward_color_10: '#a18270',
  reward_color_30: '#9a9a9a',
  reward_color_60: '#d29f00',
  reward_color_100: '#738ffd',
  grey_01_bg: '#f7f7f7',
  grey_02_line: '#e5e5e5',
  grey_03_icon: '#ccc',
  grey_04_inactive: '#999',
  grey_05_subtext: '#444',
  black_01_basic: '#000',
  black_02_pressed: 'rgba(0, 0, 0, 0.5)',
  black_03_floating: 'rgba(0, 0, 0, 0.95)',
  white_01_basic: '#fff',
  white_02_floating: 'rgba(255, 255, 255, 0.95)',
  white_03_pressed: 'rgba(255, 255, 255, 0.5)',
};

export const iconColors = {
  tab_active: `${colors.grey_05_subtext}`,
  tab_inactive: `${colors.grey_03_icon}`,
};
