import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React from 'react';
import { colors } from 'src/styles/theme';

interface ICircle {
  children: React.ReactNode;
  type?: 'recommend' | 'middle' | 'lack';
  style?: React.CSSProperties;
  size?: string;
  className?: string;
}

function Circle(props: ICircle) {
  const {
    children,
    type = 'recommend',
    style,
    size = '1.6rem',
    className,
  } = props;

  const types = {
    recommend: css`
      border: 0.5rem solid ${colors.feedback_green_03_basic};
      color: ${colors.feedback_green_03_basic};
    `,
    middle: css`
      border: 0.5rem solid ${colors.feedback_orange_03_basic};
      color: ${colors.feedback_orange_03_basic};
    `,
    lack: css`
      border: 0.5rem solid ${colors.feedback_red_03_basic};
      color: ${colors.feedback_red_03_basic};
    `,
  };

  const BorderCircle = styled.div`
    width: 5.5rem;
    height: 5.5rem;
    border-radius: 100%;

    font-size: ${size};
    font-weight: bold;
    text-align: center;
    word-break: break-all;

    display: flex;
    justify-content: space-around;
    align-items: center;

    ${types[type]}
  `;
  return (
    <BorderCircle style={style} className={className}>
      {children}
    </BorderCircle>
  );
}

export default Circle;
