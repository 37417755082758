import {
  SetStateAction,
  Dispatch,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  useLocation,
  useNavigationType,
  UNSAFE_NavigationContext as NavigationContext,
} from 'react-router';
import { useSetRecoilState } from 'recoil';
import { useGetGoalWeight } from 'src/services/WeightQuery';
import { qstnIsBackState } from './atoms/questionnaires';

function usePrevious(path: string) {
  const ref = useRef<string>();
  useEffect(() => {
    ref.current = path;
  }, [path]);
  return ref.current;
}

const goToMainPath = ['/mission', '/remind', '/etc'];
const isSurveyPage = ['/', '/result', '/goal', '/guide'];
const noGoalPath = [
  '/mission',
  '/remind',
  '/remind/write',
  '/remind/share',
  '/reward',
];

function useHistoryRoutes(setIsExit: Dispatch<SetStateAction<boolean>>) {
  const { navigator } = useContext(NavigationContext);
  const { pathname: url, search } = useLocation();
  const navType = useNavigationType();
  const setQstnIsBack = useSetRecoilState(qstnIsBackState);
  const [initProcess, setInitProcess] = useState(false);
  const [curPath, setCurPath] = useState<string>('/weight');
  const [curQuery, setCurQuery] = useState<string>('');
  const prevPath = usePrevious(curPath);
  const { data } = useGetGoalWeight();
  // 목표체중을 설정하지 않아 baseDt 기준 데이터가 올바르게 조회되지 않을 때 or 목표달성으로 남은기간이 0일 때
  const isSucceedGoal = !data || (data && data.goalDys <= 0);

  useEffect(() => {
    setCurPath(url);
    setCurQuery(search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const isFinish =
    prevPath === '/weight' && navType === 'POP' && curPath !== '/';
  const isGoMain = goToMainPath.includes(prevPath!) && navType === 'POP';
  const isSurvey =
    isSurveyPage.includes(curPath) && curPath === prevPath && navType === 'POP';
  const isNoGoal = isSucceedGoal && noGoalPath.includes(curPath);

  useEffect(() => {
    if (isFinish) {
      navigator.push('/weight');
      setIsExit(true);
    } else if (isGoMain) {
      navigator.push('/weight');
    } else if (isSurvey) {
      if (curPath === '/result' || curPath === '/guide')
        navigator.push(`${curPath}`);
      else if (curPath === '/goal' && curQuery === '?keep=true')
        navigator.push('/goal?keep=true');
      else navigator.push('/');

      console.log(
        !(curPath === '/goal' && !curQuery),
        !curQuery.includes('?token'),
      );

      if (!(curPath === '/goal' && !curQuery)) {
        if (!initProcess) {
          setInitProcess(true);
        } else setQstnIsBack({ isBackBtn: true });
      }
    } else if (isNoGoal) {
      setQstnIsBack({ isBackBtn: false });
      navigator.replace('/goal', { state: { guide: true } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator, setIsExit, isFinish, isGoMain, isSurvey, isNoGoal]);
}

export default useHistoryRoutes;
