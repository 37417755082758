import { QueryClientProvider } from 'react-query';
import { useState } from 'react';
import { getQueryClient } from 'src/lib/request';
import { BrowserRouter } from 'react-router-dom';
import { Global } from '@emotion/react';
import { RecoilRoot } from 'recoil';
import { Slide, ToastContainer } from 'react-toastify';
import { GlobalStyles } from './styles/GlobalStyles';
import 'react-toastify/dist/ReactToastify.css';
import Router from './routes';
import ErrorBoundary from './components/common/ErrorBoundary';

function App() {
  const [isError, setIsError] = useState(false);
  const [isExit, setIsExit] = useState(false);
  return (
    <RecoilRoot>
      <QueryClientProvider client={getQueryClient(setIsError)}>
        <Global styles={GlobalStyles} />
        <ErrorBoundary>
          <BrowserRouter>
            <Router isError={isError} isExit={isExit} setIsExit={setIsExit} />
          </BrowserRouter>
          <ToastContainer transition={Slide} closeButton={false} limit={1} />
        </ErrorBoundary>
      </QueryClientProvider>
    </RecoilRoot>
  );
}

export default App;
