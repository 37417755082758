import styled from '@emotion/styled';
import { ReactComponent as LoadingSvg } from 'src/assets/img/icon-loading-spinner.svg';
import { colors } from 'src/styles/theme';

const LoadingBackground = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1024;
  background-color: ${colors.black_03_floating};
  opacity: 0.5;
`;

const OcareAnimationDiv = styled.div`
  width: 7rem;
  height: 7rem;
  position: absolute;
  z-index: 1025;
  left: 50%;
  top: 50%;
  transform: translate(-3.5rem, -3.5rem);
`;

const LoadingSpinner = styled(LoadingSvg)`
  animation: spinner 0.8s ease infinite;
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const LoadingDiv = styled.div`
  width: 15rem;
  height: 15rem;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { LoadingBackground, OcareAnimationDiv, LoadingDiv, LoadingSpinner };
