import { atom } from 'recoil';

interface IQstnEscape {
  isBackBtn: boolean;
}

export const qstnIsBackState = atom<IQstnEscape>({
  key: 'questionnaires',
  default: {
    isBackBtn: false,
  },
});
