import React, { Suspense, lazy, Dispatch, SetStateAction } from 'react';
import { Route, Routes } from 'react-router-dom';
import useHistoryRoutes from './historyRoutes';
import ExitPage from './pages/Exit/ExitPage';
import Guide from './styles/Guide';

const ErrorPage = lazy(() => import('./pages/Error/ErrorPage'));
const Mission = lazy(() => import('./pages/Mission/MissionPage'));
const Weight = lazy(() => import('./pages/Weight/WeightPage'));
const WeightDetail = lazy(() => import('./pages/Weight/WeightDetailPage'));
const Etc = lazy(() => import('./pages/Etc/EtcPage'));
const RemindList = lazy(() => import('./pages/Remind/RemindList'));
const RemindShare = lazy(() => import('./pages/Remind/RemindShare'));
const RemindWrite = lazy(() => import('./pages/Remind/RemindWrite'));
const Reward = lazy(() => import('./pages/Reward/RewardPage'));
const ReportDetailPage = lazy(() => import('./pages/Report/ReportDetailPage'));
const ReportPage = lazy(() => import('./pages/Report/ReportPage'));
const Goal = lazy(() => import('./pages/Goal/GoalPage'));
const AchievementGuide = lazy(
  () => import('./pages/AchievementGuide/AchievementGuidePage'),
);
const Questionnaire = lazy(
  () => import('./pages/Questionnaires/QuestionnairesPage'),
);
const QuestionResult = lazy(
  () => import('./pages/QuestionResult/QuestionResultPage'),
);
const CheckQuestionnaires = lazy(
  () => import('./components/CheckQuestionnaires/CheckQuestionnaires'),
);

export interface ISubRoute {
  key: string;
  path: string;
  element: React.ReactNode;
}

export interface IRouteList {
  key: string;
  path: string;
  element: React.ReactNode;
  sub?: ISubRoute[];
}

export const routeList: IRouteList[] = [
  {
    key: 'questionnaires',
    path: '/',
    element: <Questionnaire />,
  },
  {
    key: 'questionResult',
    path: '/result',
    element: <QuestionResult />,
  },
  {
    key: 'weight',
    path: '/weight',
    element: <Weight />,
  },
  {
    key: 'weightRecord',
    path: '/weight/:type',
    element: <WeightDetail />,
  },
  {
    key: 'mission',
    path: '/mission',
    element: <Mission />,
  },
  {
    key: 'remind',
    path: '/remind',
    element: <RemindList />,
  },
  {
    key: 'remind/write',
    path: '/remind/write',
    element: <RemindWrite />,
  },
  {
    key: 'remind/share',
    path: '/remind/share',
    element: <RemindShare />,
  },
  {
    key: 'reward',
    path: '/reward',
    element: <Reward />,
  },
  {
    key: 'etc',
    path: '/etc',
    element: <Etc />,
  },
  {
    key: 'report',
    path: '/etc/report',
    element: <ReportPage />,
  },
  {
    key: 'reportDetail',
    path: '/etc/report/detail',
    element: <ReportDetailPage />,
  },
  {
    key: 'goal',
    path: '/goal',
    element: <Goal />,
  },
  {
    key: 'AchievementGuide',
    path: '/guide',
    element: <AchievementGuide />,
  },
  {
    key: 'style',
    path: '/style/guide',
    element: <Guide />,
  },
];

interface IRouter {
  isError: boolean;
  isExit: boolean;
  setIsExit: Dispatch<SetStateAction<boolean>>;
}

function Router({ isError, isExit, setIsExit }: IRouter) {
  useHistoryRoutes(setIsExit);

  if (isExit) return <ExitPage setIsExit={setIsExit} />;
  if (isError) return <ErrorPage />;

  return !isError ? (
    <Suspense fallback={null}>
      <Routes>
        {routeList.map((route) => (
          <Route
            {...route}
            element={<CheckQuestionnaires>{route.element}</CheckQuestionnaires>}
          >
            {route.sub?.map((subRoute) => (
              <Route {...subRoute} />
            ))}
          </Route>
        ))}
      </Routes>
    </Suspense>
  ) : (
    <ErrorPage />
  );
}

export default Router;
