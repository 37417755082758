import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'src/App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const loading = document.getElementById('loading');
const url = new URL(window.location.href);
const urlToken = url.searchParams.get('token');
const token = decodeURIComponent(urlToken!);

axios
  .post(
    `${process.env.REACT_APP_BASE_URL}/loginHome`,
    {},
    {
      headers: {
        Authorization: token!,
      },
    },
  )
  .then((data) => {
    if (data.data === 'SUCCESS') {
      window.history.pushState(null, '', window.location.href);
    } else {
      window.history.pushState(null, '', window.location.origin);
      window.sessionStorage.clear();
    }
  })
  .catch(() => {
    window.history.pushState(null, '', window.location.origin);
    window.sessionStorage.clear();
  })
  .finally(() => {
    loading?.remove();
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  });

// if (process.env.NODE_ENV === 'development') {
//   /* eslint-disable global-require */
//   const { worker } = require('./mocks/browser');
//   worker.start();
// }
