import { callFinish } from 'src/lib/utils';
import CustomModal from 'src/components/common/Modal/CustomModal';
import { Dispatch, SetStateAction } from 'react';

interface IExitPage {
  setIsExit: Dispatch<SetStateAction<boolean>>;
}

function ExitPage({ setIsExit }: IExitPage) {
  const handleOnOk = () => {
    callFinish();
  };

  const handleOnCancel = () => {
    setIsExit(false);
  };

  return (
    <CustomModal
      cancelButton
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      cancelText="아니오"
      okText="예"
    >
      <div>오케어 앱으로 이동하시겠습니까?</div>
    </CustomModal>
  );
}

export default ExitPage;
