import React, { useState } from 'react';
import styled from '@emotion/styled';
import { colors } from 'src/styles/theme';

interface ISwitchButton {
  on?: boolean;
  onClick?: React.ChangeEventHandler<HTMLElement>;
  className?: string;
  style?: React.CSSProperties;
}

const Wrapper = styled.div`
  input:checked + label:after {
    left: calc(100% - 2rem + 0.3rem);
    background: ${colors.primary_03_basic};
  }

  input:checked + label {
    background: ${colors.primary_02_light};
  }
`;

const Switch = styled.input`
  position: absolute;
  display: none;
  left: 0;
`;

const Label = styled.label`
  position: relative;
  display: block;
  width: 3.5rem;
  height: 1.44rem;
  background: ${colors.grey_03_icon};
  border-radius: 60px;
  transition: background 0.4s;

  &:after {
    content: '';
    position: absolute;
    left: -0.3rem;
    top: 50%; // 부모의 height 기준으로 동작
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    background: #f5f5f5;
    transform: translateY(-50%); // 자신의 height 기준으로 동작
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24);
    transition: all 0.4s;
  }
`;

function SwitchButton(props: ISwitchButton) {
  const { className, style, on = false } = props;
  const [checked, setChecked] = useState(on);

  const clickHandler = () => {
    setChecked(!checked);
  };

  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const check = e.currentTarget.checked;
    const { onClick } = props;
    if (onClick) onClick(e);
  };

  return (
    <Wrapper>
      <Switch
        type="checkbox"
        id="switch"
        onChange={changeHandler}
        onClick={clickHandler}
        className={className}
        style={style}
        checked={checked}
      />
      <Label htmlFor="switch" />
    </Wrapper>
  );
}

export default SwitchButton;
