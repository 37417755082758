import styled from '@emotion/styled';
import { colors } from '../../../styles/theme';

export const ModalBackground = styled.div`
  position: fixed;
  z-index: 101;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.black_03_floating};
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 28rem;
  border-radius: 1.6rem;
  overflow: hidden;
  background-color: ${colors.white_01_basic};
  font-size: 1.6rem;
  text-align: center;
  word-break: keep-all;
`;

export const Contents = styled.div`
  padding: 5rem 2rem;
`;

export const ModalButtonContainer = styled.div`
  height: 5rem;
  display: flex;
  width: 100%;
  button {
    flex: 1;
    line-height: 5rem;
    text-align: center;
  }
  button.cancel {
    background-color: ${colors.primary_01_highlight};
  }
  button.confirm {
    background-color: ${colors.primary_03_basic};
  }
  button.mission-confirm {
    background-color: ${colors.primary_03_basic};
    font-size: 1.5rem;
    color: ${colors.black_01_basic};
    font-weight: bold;
  }
`;
