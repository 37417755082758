import styled from '@emotion/styled';
import {
  Button,
  Category,
  Circle,
  SwitchBtn,
  Tag,
} from 'src/components/common';
import CustomModal from 'src/components/common/Modal/CustomModal';
import useModal from '../hooks/useModal';

const GuideStyle = styled.div`
  width: 100%;
  padding: 2rem;

  h2 {
    font-size: 2rem;
    padding-bottom: 1rem;
  }
  h3 {
    font-size: 1.2rem;
    padding-bottom: 1rem;
  }
  h4 {
    font-size: 1rem;
    padding-top: 0.8rem;
  }

  article {
    section {
      border-top: 1px solid #ddd;

      & > {
        div {
          display: flex;
          justify-content: space-around;
          align-items: center;
        }
      }
    }
  }
`;

function Guide() {
  const { visible, open, close } = useModal();
  const handleOK = () => {
    // # [example] when a user clicks the OK button
    close();
  };
  return (
    <>
      {visible && (
        <CustomModal cancelButton onCancel={close} onOk={handleOK}>
          나의 다짐 작성을 취소하시겠습니까?
        </CustomModal>
      )}
      <GuideStyle>
        <article>
          <h2>OnOff</h2>
          <section>
            <h3>Switch - (Click)</h3>
            <div>
              <SwitchBtn onClick={console.log} />
            </div>
          </section>

          <h2>Tag</h2>
          <section>
            <h3>Tag types</h3>
            <div>
              <Tag type="basic1">basic1</Tag>
              <Tag type="basic2">basic2</Tag>
              <Tag type="basic3">basic3</Tag>
              <Tag type="basic4">basic4</Tag>
              <Tag type="basic5">basic5</Tag>
              <Tag type="basic6">basic6</Tag>
            </div>
          </section>

          <h2>Circle</h2>
          <section>
            <h3>Circle types</h3>

            <h4>recommend, middle, lack</h4>
            <div>
              <Circle type="recommend">권장</Circle>
              <Circle type="middle">조금 많음</Circle>
              <Circle type="middle">중위험</Circle>
              <Circle type="lack">부족</Circle>
            </div>
          </section>

          <h2>Button</h2>
          <section>
            <h3>Button types</h3>

            <h4>Round</h4>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="round">round</Button>
            </div>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="round" disabled>
                round disabeld
              </Button>
            </div>

            <h4>Round2</h4>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="round2">round2</Button>
            </div>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="round2" disabled>
                round2 disabeld
              </Button>
            </div>

            <h4>Rectangle</h4>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="rectangle">rectangle</Button>
            </div>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="rectangle" disabled>
                rectangle disabeld
              </Button>
            </div>

            <h4>Rectangle2</h4>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="rectangle2">rectangle2</Button>
            </div>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="rectangle2" disabled>
                rectangle2 disabeld
              </Button>
            </div>

            <h4>circle</h4>
            <div style={{ marginBottom: '3rem' }}>
              <Button type="circle">예</Button>
              <Button type="circle">아니오</Button>
              <Button type="circle">가끔</Button>
              <Button type="circle" disabled>
                disabled
              </Button>
            </div>
          </section>
          <section>
            <h3>Button size</h3>
            <h4>small</h4>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="round" size="small">
                round small width auto
              </Button>
            </div>
            <h4>medium(default)</h4>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="round2" size="medium">
                round2 medium width 100%
              </Button>
            </div>
            <h4>large</h4>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="round" size="large">
                round large width 100%
              </Button>
            </div>
          </section>
          <section>
            <h3>Button tag a(Link)</h3>
            <h4>Link</h4>
            <div style={{ display: 'block', marginBottom: '3rem' }}>
              <Button type="round" size="medium" tag="a" to="/">
                Link (no Button)
              </Button>
            </div>
          </section>

          <h2>Category</h2>
          <section>
            <h3>Category types</h3>
            <div style={{ background: 'grey', height: '5rem' }}>
              <Category active>active</Category>
              <Category>inactive</Category>
            </div>
          </section>

          <h2>Modal</h2>
          <section>
            <h3>useModal</h3>
            <div style={{ height: '5rem' }}>
              <Button type="round" size="medium" onClick={open}>
                OPEN MODAL
              </Button>
            </div>
          </section>
        </article>
      </GuideStyle>
    </>
  );
}

export default Guide;
