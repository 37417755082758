import { format, parseISO } from 'date-fns';
import { ko } from 'date-fns/locale';

const FORMAT = 'yyyy-MM-dd';

export function getFormattedDate(baseDate: Date, givenFormat: string = FORMAT) {
  return format(baseDate, givenFormat, { locale: ko });
}

export function parseToDate(date: string) {
  return parseISO(date);
}

export function numberWithCommas(value: string | number | undefined) {
  if (value !== '-') {
    let convertedValue = Number(Number(value).toFixed(1));
    convertedValue = Number.isInteger(convertedValue)
      ? Math.floor(convertedValue)
      : convertedValue;
    return convertedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return value;
}

export function calculatePoint(num: number, decimalPlace: number = 10) {
  const result = Math.floor(num * decimalPlace) / decimalPlace;
  return result;
}

// 현재 호출한 디바이스가 어떤 것인지 체크합니다.
const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i) != null;
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) != null;
  },
  any() {
    return isMobile.Android() || isMobile.iOS();
  },
};

export function ocareJson(obj = {}) {
  const defaultJson = {
    cat_code: '',
    cat_code_detail: '',
    providerId: '1000000040',
    typeKey: 'carenbalance',
    params: '',
  };

  return { ...defaultJson, ...obj };
}

// 입력 된 링크를 전달하는 Bridge 함수
export function callFinish(json = {}) {
  console.log('callFinish :: ', json);
  if (isMobile.any()) {
    if (isMobile.Android()) {
      /* global Android */
      /* eslint no-undef: "error" */
      // @ts-ignore
      Android.callFinish(JSON.stringify(json));
      console.log('Android callFinish');
    } else if (isMobile.iOS()) {
      /* global webkit */
      /* eslint no-undef: "error" */
      // @ts-ignore
      webkit.messageHandlers.callFinish.postMessage(json);
      console.log('IOS callFinish');
    }
  }
}
