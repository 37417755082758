import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { colors } from 'src/styles/theme';
import loadingSpinner from '../../../assets/img/icon-loadingSpinner.svg';

interface IButton {
  children: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  tag?: 'a';
  type?: 'round' | 'round2' | 'round3' | 'rectangle' | 'rectangle2' | 'circle';
  size?: 'small' | 'medium' | 'large';
  className?: string;
  htmlType?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  style?: React.CSSProperties;
  to?: string;
}

interface IButtonTagA {
  buttontype: string;
  to: string;
}

interface IButtonTag {
  buttontype: string;
}

function Button(props: IButton) {
  const {
    children,
    tag,
    type = 'round',
    size = 'medium',
    className,
    htmlType = 'button',
    disabled: disabledProp = false,
    loading = false,
    icon,
    style,
    to,
  } = props;

  const types = {
    round: css`
      font-weight: bold;
      border-radius: 2.3rem;
      background: ${colors.primary_03_basic};
      color: ${colors.black_01_basic};

      &:active {
        color: ${colors.black_02_pressed};
        background-color: ${colors.primary_02_light};
      }
    `,
    round2: css`
      font-weight: bold;
      border-radius: 2.3rem;
      background: ${colors.black_01_basic};
      color: ${colors.white_01_basic};

      &:active {
        color: ${colors.white_03_pressed};
        background-color: ${colors.black_02_pressed};
      }
    `,
    round3: css`
      font-weight: bold;
      border-radius: 2.3rem;
      background: ${colors.white_01_basic};
      color: ${colors.black_01_basic};
      border: 1px solid ${colors.black_01_basic};

      &:active {
        color: ${colors.black_01_basic};
        background-color: ${colors.white_01_basic};
      }
    `,
    rectangle: css`
      font-weight: bold;
      background: ${colors.primary_03_basic};
      color: ${colors.black_01_basic};

      &:active {
        color: ${colors.black_02_pressed};
        background-color: ${colors.primary_02_light};
      }
    `,
    rectangle2: css`
      font-weight: bold;
      background: ${colors.primary_01_highlight};
      color: ${colors.black_01_basic};

      &:active {
        color: ${colors.black_02_pressed};
        background-color: ${colors.primary_01_highlight};
      }
    `,
    circle: css`
      width: 8rem;
      height: 8rem;
      border-radius: 100%;
      font-size: ${className === 'mission' ? '1.4rem' : '1.8rem'};
      font-weight: bold;
      text-align: center;
      word-break: keep-all;
      border: 1rem solid ${colors.black_01_basic};
      color: ${colors.black_01_basic};
    `,
  };

  const sizes = {
    icon: css``,
    small: css`
      width: auto;
      font-size: 1.2rem;
      padding: 0.6rem 1.2rem;
    `,
    medium: css`
      width: 100%;

      font-size: 1.4rem;
      padding: 1.4rem 2rem;
    `,
    large: css`
      width: 100%;
      font-size: 1.6rem;
      padding: 1.8rem;
    `,
  };

  const ButtonStyle = styled.button<IButtonTag>`
    ${types[type]}
    ${({ buttontype }) => buttontype !== 'circle' && sizes[size]}
    ${({ disabled, buttontype }) =>
      disabled &&
      buttontype !== 'circle' &&
      css`
        background: ${colors.grey_03_icon};
        color: ${colors.white_01_basic};
        /* opacity: 0.5; */
        cursor: not-allowed;
        pointer-events: none;
      `}
    ${({ disabled, buttontype }) =>
      disabled &&
      buttontype === 'circle' &&
      css`
        opacity: 0.2;
        cursor: not-allowed;
        pointer-events: none;
      `}

    .loading {
      width: 2rem;
      height: 2rem;
    }
  `;

  const AnchorStyle = styled(ButtonStyle.withComponent(Link))<IButtonTagA>`
    display: inline-block;
    text-align: center;
  `;

  const clickHandler = (
    e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>,
  ) => {
    if (disabledProp || loading) {
      return;
    }
    const { onClick } = props;
    if (onClick) onClick(e);
  };

  if (tag === 'a') {
    return (
      <AnchorStyle buttontype={type} to={to || ''} style={style}>
        {children}
        {icon && icon}
      </AnchorStyle>
    );
  }

  return (
    <ButtonStyle
      buttontype={type}
      className={className}
      type={htmlType}
      onClick={clickHandler}
      disabled={loading || disabledProp}
      style={style}
    >
      {loading && (
        <img src={loadingSpinner} alt="loading" className="loading" />
      )}
      {children}
      {icon && icon}
    </ButtonStyle>
  );
}

export default Button;
